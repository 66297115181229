/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

@import url("https://fonts.googleapis.com/css?family=Play:normal,bold");

:root {
  --background-color-greenish: #10EBFF;
  --background-color-dark: rgba(0, 50, 80, 0.85);
  --background-color-button: #FF108C;
  --background-color-button-dark: #C60F6E;
  --block-margin: 0px 0px 48px 0px;
  --block-padding: 10px 0px 10px 0px;
  --block-title-margin: 29px auto 29px auto;
  --block-message-margin: 0px auto 29px auto;
  --block-message-padding: 0px auto;
  --block-max-width: 980px;
  --color-title: #0F293C;
  --color-message: #3E5363;
  --color-message-light: white;
  --color-button: white;
  --color-accent-turq: #10EBFF;
  --color-accent-pink: #FF108C;
  --color-accent-pink-dark: #C60F6E;
  --color-accent-orange: #FF6700;
  --color-accent-green: #6eff00;
  --content-width: 80%;
  --content-max-width: 850px;
  --content-margin: auto;
  --font-size-big-message: 1.25rem;
  --font-size-title-message: calc(10px + 1.2vmin);
  --font-size-message: 16px;
  --font-size-button: 16px;
  --font-size-minor-title: 24px;
  --font-size-title: 36px;
  --minor-block-title-margin: 29px auto 29px auto;
  --width-button-standard: 30%;
  --min-width-button-standard: 150px;
}

body {
  margin: 0;
  font-family: 'Play', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.07rem;
  color: var(--color-message);
  overflow-wrap: break-word;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

video {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

a {
  color: var(--color-accent-turq);
  text-decoration: none;
}

a.text-link {
  color: var(--color-message);
  text-decoration: none;
}

a:hover {
  color: var(--color-accent-pink);
  border-radius: 5px;
  cursor: pointer;
}

a:active {
  background-color: var(--color-accent-pink);
}

/*a:active {
  background-color: var(--color-accent-turq);
}*/

a.pink-link, a.pink-link:visited {
  color: var(--color-accent-pink);
  text-decoration: none;
}

a.pink-link:hover {
  color: var(--color-accent-turq);
  border-radius: 5px;
  cursor: pointer;
}

a.pink-link:active {
  color: var(--color-accent-turq);
  border-radius: 5px;
  background-color: var(--color-accent-pink);
  cursor: pointer;
}

#mintBarId_1 a.pink-link, 
#mintBarId_1 a.pink-link:visited {
  color: var(--color-accent-orange);
  text-decoration: none;
}

#mintBarId_1 a.pink-link:hover {
  color: var(--color-accent-green);
  border-radius: 5px;
  cursor: pointer;
}

#mintBarId_1 a.pink-link:active {
  color: var(--color-accent-green);
  background-color: var(--color-accent-orange);
}

a.turq-link, a.turq-link:visited {
  color: var(--color-accent-turq);
  text-decoration: none;
}

a.turq-link:hover {
  color: var(--color-accent-pink);
  border-radius: 5px;
  cursor: pointer;
}

a.turq-link:active {
  color: var(--color-accent-pink);
  border-radius: 5px;
  background-color: var(--color-accent-turq);
  cursor: pointer;
}

.center-text {
  text-align: center;
}

.bold-text {
  font-weight: bold;
}

.pink-text {
  color: deeppink;
}

.list-item-pricing {
  list-style-type: none;
  padding: 4px 0px;
}

.list-item-pricing.strikethrough {
  text-decoration: line-through;
}

.external-link-wrap {
  min-width: 250px;
  max-width: 40%;
  margin: auto;
  padding: 5px 5px;
  outline: none;
  height: 1em;
  line-height: 1em;
}

.inline-link-as-button-wrap {
  min-width: 250px;
  padding: 5px 2px;
  outline: none;
  height: 1.3em;
  line-height: 1.3em;
}

a.link-as-button {
  display: block;
  color: var(--color-message-light);
  background-color: var(--color-accent-pink);
  /*padding: 0.5em 4.5em;*/
  text-decoration: none;
  border-radius: 10px;
  text-align: center;
  font-size: var(--font-size-button);
  font-weight: bold;
  font-family: 'Play', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Arial';
  text-transform: uppercase;
  -webkit-appearance: none;
  -moz-appearance: none;
  vertical-align: middle;
}

a.inline-link-as-button {
  display: inline-block;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  width: 12em;
  height: 1.2em;
  line-height: 1.2em;
  font-size: var(--font-size-title-message);
}

a.link-as-button:hover,
a.link-as-button:focus {
  color: var(--color-message-light);
  background-color: var(--color-accent-turq);
}

a.link-as-button:active {
  background-color: cyan;
}


button {
  display: block;
  margin: auto;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
  border: 3px solid transparent;
  border-radius: 10px;
  text-align: center;
  min-height: 2em;
  font-size: var(--font-size-button);
  font-weight: bold;
  font-family: 'Play', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Arial';
  width: 296px;
  max-width: 85%;
  padding: 1px 5px;
  outline: none;
  text-transform: uppercase;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button.btn-inverse {
  color: var(--background-color-button);
  border: 3px solid var(--background-color-button);
  background-color: var(--color-button);
}

button.btn-inverse:hover,
button.btn-inverse:focus {
  color: var(--color-button);
  border: 3px solid var(--color-button);
  background-color: var(--background-color-button);
}

button.btn-transparent {
  color: var(--color-message-light);
  border: 3px solid var(--color-message-light);
  background-color: transparent;
}

button.btn-type-a {
  color: var(--color-message-light);
  background-color: var(--color-accent-pink);
}

button.btn-type-b {
  color: var(--color-message);
  background-color: var(--color-accent-turq);
}


button.btn-type-c {
  color: var(--color-message-light);
  background-color: var(--color-accent-orange);
}

button:hover,
button:focus {
  color: var(--color-message-light);
  border: 3px solid transparent;
  background-color: var(--color-accent-turq);
  cursor: pointer;
}

button.btn-type-c:hover,
button.btn-type-c:focus {
  color: var(--color-message-light);
  border: 3px solid transparent;
  background-color: var(--color-accent-green);
  cursor: pointer;
}

button.btn-transparent:hover,
button.btn-transparent:focus {
  border-color: var(--color-accent-turq);
}

button:active {
    -webkit-transform: scale(0.98);
        -ms-transform: scale(0.98);
            transform: scale(0.98);
}

li {
  list-style-type: lower-greek;
  list-style-position: outside;
  padding: 7px 2px;
}

.justify-text {
  text-align: justify;
}

.power-icon {
  height: var(--font-size-button);
  height: 80%;
}

.all-wrapper {
  margin: 0px auto;
  max-width: 100vw;
}

.bg-wrapper {
  background-color: #282c34;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center, center;
}

.content-wrapper {
  position: relative;
  width: 90%;
  max-width: var(--block-max-width);
  height: 100%;
  color: var(--color-message);
  margin: auto;
}

.bounties-board-title {

}

.bounty-gallery-wrap {
  width: 90%;
 /* min-width: 250px;*/
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.bounty-tile-wrap {
  position: relative;
  width: 115px;
  height: 115px;
  margin: 5px 2px;
  background-color: lightcyan;
  border: 3px solid var(--color-accent-turq);
  border-radius: 5px;
  padding: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.bounty-tile-wrap:hover {
  border-color: var(--color-accent-pink);
  cursor: pointer;
}

.bounty-tile-message-wrap {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
}

.post-block .bounty-tile-message-wrap p {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  color: black;
}

.post-block .bounty-tile-message-wrap .bounty-reward-wrap p {
  font-size: 20px;
  color: var(--color-accent-pink);
}

.bounty-reward-wrap {
  position: absolute;
  margin: auto;
  width: 100%;
  background-color: rgba(255,255,255,0.8);
  bottom: 0;
}

.bounty-target-wrap {
  position: absolute;
  margin: auto;
  width: 100%;
  background-color: rgba(255,255,255,0.8);
  top: 0;
}

.bounty-modal-title-wrap {
  max-height: 10%;
  margin: 0 auto;
}

.bounty-modal h1,
.bounty-modal h2{
  color: deeppink;
  padding: 0;
  margin: 0 auto 5px auto;
  font-size: calc(16px + 4vmin);

}

.bounty-modal .stats-modal-image-frame {
  max-height: 30%;
}

.bounty-modal .stats-modal-container {
  height: 100%;
}

.bounty-modal-message-frame {
  margin: auto;
  max-height: 60%;
}

.bounty-message-content h2,
.bounty-message-content h3,
.bounty-message-content h4 {
  margin: 10px auto;
}

.post-block .bounty-message-content h3 {
  font-size: var(--font-size-title-message);
}

.post-block .bounty-message-content p,
.post-block .bounty-footer-wrap p {
  margin: 5px auto;
  font-size: var(--font-title-message);
}

.bounty-modal-footer {
  margin: auto;
  max-height: 15%;
}

.bounty-claimed-overlay-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
}

.post-block .bounty-claimed-overlay-wrap p {
  font-size: 20px;
  font-weight: bold;
  margin: 1px 1px;
  color: var(--color-accent-pink);
  color: black;
}

.banner-stripe {
  width: 100%;
  margin: auto;
  top: 0;
  background-color: var(--color-accent-turq);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
  line-height: 1.5em;
}

.banner-stripe .contents-wrap {
  margin: auto;
  display: block;
  text-align: center;
  color: var(--color-message-light);
  font-size: var(--font-size-minor-title);
}

.banner-stripe .contents-wrap p {
  margin: auto;
  font-size: large;
}

#mintBarId_1 .banner-stripe {
  background-color: var(--color-accent-orange);
}

.connect-modal-underlay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 100, 100, 0.25);
}

.connect-modal-container {
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 80%;
  max-width: 600px;
}

.connect-buttons-container {
  z-index: 1000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin: auto;
  border-radius: 8px;
  background-color: white;
}

.close-button-container {
  background-color: transparent;
  width: 10%;
  min-width: 5%;
  margin: 0 0 2px auto;
  height: 35px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.close-button-container button {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.close-button-icon {
  position: relative;
}

.close-button-icon svg{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: block;
  margin: auto;
}

.connect-modal-button {
  border-radius: 3em;
  width: 75%;
}

.connect-modal-button.btn-connected {
  border-color: #33FF00;
}

.connect-modal-button.btn-activating {
  border-color: orangered;
}

button.connect-modal-button{
  border-radius: 3rem;
  cursor: pointer;
  position: relative;
  background-color: white;
  margin: 5px 5px;
}

button.connect-modal-button:hover,
button.connect-modal-button:focus {
  border: 3px solid var(--color-accent-turq);
}

.connect-modal-button-content {
  position: relative;
  margin: auto;
  width: 80%;
  height: 95%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--color-message);
  background-color: white;
}

/*.connect-modal-open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}*/

.connect-modal-open-button-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: safe center;
      -ms-flex-align: safe center;
          align-items: safe center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin: auto;
}

.connect-modal-open-button-content {
  max-width: 90%;
}

.cols-block-container {
  width: 80%;
  max-width: var(--block-max-width);
  margin: auto;
}

.cols-block-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: safe flex-start;
      -ms-flex-align: safe flex-start;
          align-items: safe flex-start;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.flex-cols.wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.gallery-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: safe center;
      -ms-flex-pack: safe center;
          justify-content: safe center;
  -webkit-box-align: safe center;
      -ms-flex-align: safe center;
          align-items: safe center;
  width: 100%;
  padding: 0px;
  margin-bottom: 35px;
}

button.gallery-refresh-button {
  width: var(--width-button-standard);
  min-width: var(--min-width-button-standard);
}

.gallery-scroller {
  max-height: 350px;
/*  overflow-y: auto;
*/}

.gallery-tile {
  -ms-flex-negative: 1;
      flex-shrink: 1;
  width: 28%;
  max-width: 320px;
  height: auto;
  margin: 5px;
}

.gallery-tile-user  {
  -ms-flex-negative: 1;
      flex-shrink: 1;
  width: 17%;
  height: auto;
  min-width: 50px;
  max-width: 160px;
  margin: 3px;
  border: 2px solid transparent;
}

.gallery-tile-user:hover {
  border: 2px solid var(--color-accent-pink);
}

.gallery-tile-user img {
  width: 100%;
  height: auto;
  display: block;
}

.header-bar {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 1.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: safe center;
      -ms-flex-pack: safe center;
          justify-content: safe center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 1000;
  padding-bottom: 15px;
}

.header-bar .link-button-wrap {
  width: 10%;
  min-width: 120px;
  height: 100%;
  margin: 0;
  padding: 0;
}

.header-bar button {
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: 0;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: small;
  margin: 0;
  padding: 0px 3px;
  border: 0;
}

button.btn-header:hover,
button.btn-header:focus {
  background-color: rgba(0, 0, 0, 0.1);
} 

.hiding-image-organiser {
  position: relative;
}

.hiding-image-container {
  position: relative;
  display: block;
  width: 100%;
  height: 9vw;
  top: 0;
  left: 0;
}

.hiding-image-wrap {
  position: relative;
  height: 100%;
  left: 2vw;
}

img.hiding-image {
  display: block;
  width: 20vw;
}

.stats-tile-item {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 20%;
  max-width: 320px;
  min-width: 180px;
  height: auto;
  margin: 10px;
}

.stats-tile-item img {
  display: block;
  max-width: 85%;
  margin: 5px auto 5px auto;
}

.cols-block-title {
  color: #0F293C;
  font-size: var(--font-size-title);
  width: var(--content-width);
  max-width: var(--content-max-width);
  margin: var(--minor-block-title-margin)
}

.stats-tile-title {
  color: #0F293C;
  font-size: var(--font-size-minor-title);
  letter-spacing: 0.05em;
  font-weight: bolder;
  margin: 0px auto 11px auto;
}

.stats-tile-message {
  color: var(--color-message);
  font-size: var(--font-size-message);
}

.media-block {
  width: 100%;
  max-width: var(--block-max-width);
  margin: var(--block-message-padding);
}

.media-block-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  /*max-width: var(--block-max-width);
  margin: var(--block-message-padding);*/
}

.media-wrapper {
  width: 100%;
  margin: auto;
  padding: 0px;
  /*padding: 0px 0px 29px 0px;*/
}

.media-wrapper-header {
  width: 100%;
}

.media-wrapper-body {
  width: 50%;
  min-width: 250px;
  margin: auto;
}

.mint-bar-all-wrapper {
  width: 100%;
  max-width: var(--block-max-width);
  margin: auto;
}

.mint-bar-container {
  width: 100%;
  margin: 10px 0px;
}

.mint-bar-title {
  text-align: center;
}

.mint-bar-subtitle {
  width: 80%;
  margin: auto;
}

.mint-bar-content-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: var(--block-max-width);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px;
  margin: 10px auto;
}

.mint-bar-double-content-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
}

.mint-bar-double-item {
  width: 50%;
  margin: auto;
}

.mint-bar-button {
  /*margin-left: 10px;*/
  width: var(--width-button-standard);
  min-width: var(--min-width-button-standard);
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  border-radius: 10px;
}

.mint-bar-form {
  width: 10%;
  min-width: 70px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: auto;
}

.mint-bar-input-wrapper {
  width: 100%;
}

.mint-bar-number-input-wrap {
  margin: 10px auto;
}

.mint-bar-number {
  display: inline-block;
  width: 67px;
  height: 67px;
  border-radius: 10px;
  font-size: 38px;
  text-align: center;
  font-weight: bold;
  color: var(--color-message);
  background-color: var(--color-accent-turq);
  font-family: 'Play', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Arial', sans-serif;;
}

#mintBarId_1 .mint-bar-number {
  background-color: var(--color-accent-orange);
  color: white;
}

.stats-modal-message-frame .mint-bar-number {
  height: unset;
}

.modal-stats {
  max-height: 300px;
}

.modal-wrapper {
  z-index: 1010;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgb(255,255,255);
  width: 500px;
  max-width: 85%;
  height: 600px;
  max-height: 75%;
  padding: 10px;
  border-radius: 5px;
  border: 10px solid var(--color-accent-turq);
}

.modal-wrapper:hover {
  border: 10px solid var(--color-accent-pink);
}

.mint-modal-message-content p {
  margin: 0.5em auto;
}

.scrollable {
  overflow-y: auto;
}

.stats-modal-container {
  display: block;
  height: 100%;/*calc(100% - 35px);*/
  width: 98%;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: safe center;
      -ms-flex-pack: safe center;
          justify-content: safe center;
  -webkit-box-align: safe center;
      -ms-flex-align: safe center;
          align-items: safe center;
}

.stats-modal-image-frame {
  margin: auto;
  max-height: 45%;
}

.stats-modal-image-frame.secret-modal {
  max-height: 30%;
}

.stats-modal-image-frame img {
  height: 100%;
  max-height: 320px;
  width: auto;
  max-width: 100%;
  margin: auto;
}

.stats-modal-message-frame {
  text-align: center;
  margin: auto;
  max-height: 45%;
  width: 100%;
}

.stats-modal-message-frame.secret-modal {
  max-height: 70%;
}

.stats-modal-loading-message p {
  font-size: var(--font-size-minor-title);
  max-width: 90%;
  text-align: center;
  margin: auto;
  padding: 5px 0px;
}

.stats-modal-footer {
  max-height: 10%;
}

.stats-modal-footer a {
  font-size: var(--font-size-minor-title);
  margin: auto;
}

.stats-modal-footer p {
  -webkit-margin-before: 0.25em;
          margin-block-start: 0.25em;
  -webkit-margin-after: 0.25em;
          margin-block-end: 0.25em;
}

.json-readout {
  height: 100%;
}

.json-readout-stats {
  max-height: 85%;
  margin: 2px 0px;
}

.json-readout-title {
  text-align: center;
}

.json-readout-title h4 {
  margin: 5px auto;
}

.modal-overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 100, 100, 0.25);
}

.roadmap-hype p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: justify-all;
}

.subsection {
  margin-top: 3em;
}

.subsection:hover {
  background-color: #10EBFF10;
}

.overlay-darken {
  background-color: rgba(10, 6, 18, 0.3);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.post-block {
  position: relative;
  background-color: rgb(255, 255, 255);
  margin: 0px 0px 45px 0px;
  width: 100%;
  text-align: center;
  padding: 0px 0px 30px 0px;
  border-radius: 3px;
}

 
#mintBarId_1.post-block {
  background-color: var(--background-color-dark);
}

#mintBarId_1 p,
#mintBarId_1 h2,
#mintBarId_1 h4,
#mintBarId_1 span {
  color: white;
}

.arrow-list li {
  list-style-type: ">>>";
}

/*#mintBarId_0 {
  width: auto;
  border: 10px solid var(--color-accent-pink);
}*/

.border-pink {
  width: auto;
  border: 10px solid var(--color-accent-pink);
}

.border-turq {
  width: auto;
  border: 10px solid var(--color-accent-turq);
}

.border-orange {
  width: auto;
  border: 10px solid var(--color-accent-orange);
}

.border-green {
  width: auto;
  border: 10px solid var(--color-accent-green);
}

.border-hover-pink:hover {
  width: auto;
  border: 10px solid var(--color-accent-pink);
}

.border-hover-turq:hover {
  width: auto;
  border: 10px solid var(--color-accent-turq);
}

.border-hover-green:hover {
  width: auto;
  border: 10px solid var(--color-accent-green);
}

.border-hover-orange:hover {
  width: auto;
  border: 10px solid var(--color-accent-orange);
}

.post-block h2,
.post-block h3 {
  color: var(--color-title);
}

.post-block p,
.post-block span {
  color: var(--color-message);
  font-size: var(--font-size-message);
}

.wallet-contents p,
.wallet-contents span {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.wallet-contents .accent-col {
  color: var(--color-accent-pink);
  font-weight: bold;
}

.post-title {
  font-size: var(--font-size-title);
  width: var(--content-width);
  max-width: var(--content-max-width);
  margin: var(--block-title-margin);
}

h3.post-subtitle {
  font-size: var(--font-size-minor-title);
  margin: auto;
  font-weight: bold;
  color: var(--color-accent-pink); 
  margin-bottom: 0;
}

.post-message {
  font-size: var(--font-size-message);
  max-width: var(--content-max-width);
  margin: var(--block-message-margin);
}

.post-content-wrap {
  width: var(--content-width);
  margin: auto;
}

.post-content-wrap.flex-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; 
  -webkit-box-orient: horizontal; 
  -webkit-box-direction: normal; 
      -ms-flex-direction: row; 
          flex-direction: row;
  -webkit-box-align: safe center;
      -ms-flex-align: safe center;
          align-items: safe center; 
  -webkit-box-pack: safe center; 
      -ms-flex-pack: safe center; 
          justify-content: safe center;
}

/*@media screen and (max-width: 730px) {
  .post-content-wrap.flex-wrap{
    display: flex; 
    flex-direction: column;
    align-items: safe center; 
    justify-content: safe center;
  }
}*/

.post-content-wrap.flex-wrap-rev {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; 
  -webkit-box-orient: horizontal; 
  -webkit-box-direction: reverse; 
      -ms-flex-direction: row-reverse; 
          flex-direction: row-reverse;
  -webkit-box-align: safe center;
      -ms-flex-align: safe center;
          align-items: safe center; 
  -webkit-box-pack: safe center; 
      -ms-flex-pack: safe center; 
          justify-content: safe center;
}


.post-content {
  text-align: left;
  min-width: 45%;
}

.post-media {
  max-width: 30%;
  margin: auto 30px
}

.post-media-img {
  max-width: 100%;
  display: block;
  margin: auto;
}

@media screen and (max-width: 730px) {
  .post-content-wrap.flex-wrap{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
    -webkit-box-orient: vertical; 
    -webkit-box-direction: normal; 
        -ms-flex-direction: column; 
            flex-direction: column;
    -webkit-box-align: safe center;
        -ms-flex-align: safe center;
            align-items: safe center; 
    -webkit-box-pack: safe center; 
        -ms-flex-pack: safe center; 
            justify-content: safe center;
  }
  .post-content-wrap.flex-wrap-rev {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
    -webkit-box-orient: vertical; 
    -webkit-box-direction: normal; 
        -ms-flex-direction: column; 
            flex-direction: column;
    -webkit-box-align: safe center;
        -ms-flex-align: safe center;
            align-items: safe center; 
    -webkit-box-pack: safe center; 
        -ms-flex-pack: safe center; 
            justify-content: safe center;
  }
  .post-content-wrap .post-media {
    max-width: 60%;
  }
}

.progress-bar-container h4 {
  margin-bottom: 5px;
}

.progress-bar-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: var(--color-message-light);
}

.progress-bar-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}

.progress-bar-row-ele {
  margin: 0 auto;
  width: 25%;
}

.progress-bar-left {
  margin-right: 5px;
  text-align: right;
}

.progress-bar-right {
  margin-left: 5px;
  text-align: left;
}

.progress-bar-wrap {
  margin: 14px auto;
}

.progress-bar-wrap h4 {
  color: white;
  text-align: center;
  margin: 0 auto 1em auto;
}

.progress-bar {
  background-color: var(--color-accent-turq);/*#d8d8d8;*/
  border-radius: 20px;
  position: relative;
  margin: 15px auto;
  height: 30px;
  width: 300px;
  max-width: 50%;
}

.progress-bar-fill {
  background: -webkit-gradient(linear, left top, right top, from(#F2709C), to(var(--color-accent-pink)));
  background: -o-linear-gradient(left, #F2709C, var(--color-accent-pink));
  background: linear-gradient(to right, #F2709C, var(--color-accent-pink));/*(to left, #F2709C, #FF9472);*/
  -webkit-box-shadow: 0 3px 3px -5px var(--color-accent-pink), 0 2px 5px #F2709C;
          box-shadow: 0 3px 3px -5px var(--color-accent-pink), 0 2px 5px #F2709C;
  border-radius: inherit;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  min-width: 40px;
  -webkit-transition: 1s ease 0.3s;
  -o-transition: 1s ease 0.3s;
  transition: 1s ease 0.3s;
}

.progress-bar-container .hover-popup {
  display: none;
}

.progress-bar-container:hover .hover-popup {
  z-index: 1010;
  display: block;
  position: absolute;
  -webkit-transform: translate(0, 100%);
      -ms-transform: translate(0, 100%);
          transform: translate(0, 100%);

  text-align: center;
  width: 40%;
}

.hover-popup {
  color: var(--color-message-light);
  background-color: rgba(0, 50, 80, 0.85);
  font-size: medium;
}

.hover-popup-title {
  font-weight: bold;
}

.socials-bar {

}

.socials-flex.sf-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  width: var(--content-width);
  margin: auto;
}

.socials-flex.sf-left,
.socials-flex.sf-right {
  -ms-flex-negative: 2;
      flex-shrink: 2;
  margin: auto;
  /*max-width: 30%;*/
}

.socials-flex.sf-center {
  min-width: 40%;
 -ms-flex-negative: 1;
     flex-shrink: 1;
}

.socials-flex .post-content {
  text-align: center;
}

.socials-flex.sf-block img{
  display: block;
  width: 95%;
  margin: auto;
}

.socials-flex .social-tile {
  text-align: center;
}

.socials-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: safe center;
      -ms-flex-pack: safe center;
          justify-content: safe center;
  -webkit-box-align: safe stretch;
      -ms-flex-align: safe stretch;
          align-items: safe stretch;
  width: 50%;
  margin: auto;
}

.social-tile {
  color: rgb(62, 83, 99);
  font-size: var(--font-size-message);
  margin: 0.5em;
  text-align: left;
}

.social-tile span {
  vertical-align: middle;
  background-color: none;
}

/*.social-tile a:link {
  color: rgb(62, 83, 99);
  text-decoration: none;
  background-color: none;
}
.social-tile a:visited {
  color: rgb(62, 83, 99);
  text-decoration: none;
  background-color: none;
}

.social-tile a:hover {
  background-color: #fff;
  
}
*/
.social-tile a:active {
  text-decoration: underline;
  background-color: #fff;
}
/*
.social-tile a:focus {
  color: rgb(57, 225, 20);
  text-decoration: underline;
  background-color: none;
}*/

.social-logo {
  vertical-align: middle;
}

.sticky-footer-buttons-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: safe center;
      -ms-flex-pack: safe center;
          justify-content: safe center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto;
}

@media screen and (max-width: 730px) {
  .sticky-footer-buttons-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: safe center;
        -ms-flex-pack: safe center;
            justify-content: safe center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: auto;
  }
}

.sticky-footer-buttons-wrap .link-button-wrap {
  margin: 5px 5px;
}

.sticky-tokens-bar {
  position: webkit-sticky;
  position: sticky;
  width: 100%;
  max-width: var(--block-max-width);
  bottom: 20px;
  background-color: var(--color-accent-pink);
  padding: 10px 0px;
  margin: 0px auto 45px auto;
}

.sticky-tokens-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  max-width: 100%;
  margin: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.sticky-gif-container {
  max-width: 10%;
  min-width: 32px;
  margin: auto 12px auto 0px;
}

.sticky-gif-container img {
  width: 100%;
  margin: auto;
  display: block;
}

.sticky-content-container {
  max-width: 100%;
  overflow: hidden;
}

.sticky-content-container strong,
.sticky-content-container p {
  color: var(--color-message-light);
  margin: 3px auto;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 730px) {
  .sticky-content-container strong,
  .sticky-content-container p {
    color: var(--color-message-light);
    margin: 3px auto;
    text-align: center;
    text-transform: uppercase;
    font-size: smaller;
}  
} 

.title-block {
  margin: var(--block-margin);
  margin-top: 36px;
  margin: 36px 0 10px 0;
  width: 100%;
  text-align: center;
  color: white;
}

.title-block-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.title-flex button {
  font-size: calc(2vmin + 5px);
  width: 100%;
}

button.connect-modal-button {
  border-radius: 3em;
  width: 75%;
}

.connect-modal-container button {
  font-size: var(--font-size-button);
}

.title-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.title-flex-left {
  width: 40%;
  font-weight: normal;
  text-align: right;
  margin: auto 20px auto auto;
}

.title-flex-left span{
  font-weight: normal;
  font-size: 6vmin;
  font-size: min(6vw, var(--content-width)*3);
}

.title-flex-center {
  width: 20%;
}

.title-flex-center img {
  width: 100%;
}

.title-flex-right {
  width: 40%;
}

.title-block-title {
  position: relative;
  margin: 48px auto 64px auto;
  width: var(--content-width);
  text-align: left;
  font-size: 49px;
  font-weight: normal;
}

.title-block-message {
  position: relative;
  width: var(--content-width);
  margin: 1em auto;
  font-size: var(--font-size-title-message);
}

.footer-line {
  padding: 1px 1px;
  margin: 5px;
}

.web3-error-descriptor {
  text-align: center;
  padding: 0;
  margin: 2px auto;
  font-size: var(--font-size-minor-title);
}

.roadmap-wrap p {
  margin: auto;
  line-height: 2em;
  vertical-align: middle;
  color: white;
}

.roadmap-flex-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: safe center;
      -ms-flex-align: safe center;
          align-items: safe center;
  -webkit-box-pack: safe center;
      -ms-flex-pack: safe center;
          justify-content: safe center;
}

.roadmap-rows-container {
  width: 100%;
}

.roadmap-row-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: safe center;
      -ms-flex-pack: safe center;
          justify-content: safe center;
  margin: auto;
  width: 100%;
}

.roadmap-item-wrap {
  width: 16%;
  border: 3px solid white;
  font-weight: bold;
}

.roadmap-item-wrap.double-width {
  width: calc(32% + 6px);
  /*border: 3px solid white;*/
  border-top-width: 3px;
  border-bottom-width: 3px;
}

.roadmap-item-wrap.half-width {
  width: 12%;
}

.roadmap-item-wrap.width-50 {
  width: 50%;
}

.roadmap-item-content {
  margin: auto;
  text-align: center;
}

.roadmap-table-title h4 {
  margin: 0 auto;
}

.roadmap-table-title,
.roadmap-extra-items {
  background-color: #3e5363;
  color: white;
}

.roadmap-extra-items-wrap {
  border: 3px solid white;
}

.roadmap-extra-items h4{
  font-size: normal;
  margin: 0.25em auto;
}

.roadmap-extra-items-content {
  width: 85%;
  margin: auto;
}

.no-border {
  border: none;
}

.bg-turq,
.pink-row .roadmap-item-wrap.bg-turq,
.turq-row .roadmap-item-wrap {
  background-color: var(--color-accent-turq);
}

.bg-pink,
.turq-row .roadmap-item-wrap.bg-pink,
.pink-row .roadmap-item-wrap {
  background-color: var(--color-accent-pink);
}

.bg-transparent {
  background-color: transparent;
}

.bg-header,
.header-row {
  background-color: #3e5363;
}

.top-border-none {
  border-top: none;
}

.bottom-border-none {
  border-bottom: none;
}

.left-border-none {
  border-left: none;
  padding-left: 3px;
}

.right-border-none {
  border-right: none;
  padding-right: 3px;
}

.link-button-div h4 {
  color: white;
  margin: 10px;
}